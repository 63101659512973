@import "setup.css";

.navbarheader {
	position: fixed;
	z-index: 20;
	width: 100%;
	background: rgba(255, 255, 255, 1);
	padding: 5px 0;
	top: 0;
	z-index: 10;
	box-shadow: 0 0 5px #666;
	border-bottom: 1px solid #999;

	.logo {
		img {
			max-width: 210px;
		}
	}

	nav {
		.icon-bar {
			background-color: var(--defaultColor);
		}

		.sr-only {
			position: absolute;
			width: 1px;
			height: 1px;
			padding: 0;
			margin: -1px;
			overflow: hidden;
			clip: rect(0, 0, 0, 0);
			border: 0;
		}

		.icon-bar {
			display: block;
			width: 30px;
			height: 4px;
			border-radius: 1px;
			margin: 0px 0;
		}

		.navbar-area {
			@media (min-width:992px) {
				display: flex;
			}

			@media (max-width:991px) {
				position: absolute;
				visibility: hidden;
				opacity: 0;
				width: 100%;
				background-color: #fff;
				left: 0;
				transition: all .2s ease-in-out;
				-webkit-transition: all .2s ease-in-out;
				-moz-transition: all .2s ease-in-out;
				-ms-transition: all .2s ease-in-out;
				-o-transition: all .2s ease-in-out;
				padding: 12px 0;
			}

			padding: 0;
			margin: 0;

			>li {
				list-style: none;
				margin: 0;
				position: relative;

				a {
					padding: 10px 20px;
					color: var(--defaultColor);
					line-height: 20px;
					display: block;

					@media (max-width:1199px) {
						padding: 10px 12px;
					}

					&:hover {
						color: var(--greenColor);
					}

					&.navbar-fav {
						span {
							padding: 3px 5px;
							border-radius: 3px;
							background-color: var(--skyBlueColor);
							color: var(--white);
							display: inline-block;
							line-height: 13px;
							font-size: 14px;
							margin-left: 3px;
						}
					}
				}

				&.vo-dropdown {
					&:hover {
						>a {
							background-color: #eee;
							color: var(--defaultColor);
						}
					}
				}

				.vo-dropdown-content {
					border: 1px solid rgba(0, 0, 0, .15);
					box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
					padding: 5px 0;
					right: 0;

					li {
						a {
							padding: 3px 20px;
							font-size: 14px;

							&:hover {
								background-color: #f5f5f5;
								color: var(--defaultColor);
							}

							&.menu-header {
								color: #777;
								font-size: 12px;
								pointer-events: none;
							}
						}
					}

					@media (max-width:991px) {
						position: static;
						box-shadow: none;
						border: none;
					}

					.dropdown-menu {
						border-radius: 0;

						li {
							a {
								line-height: 16px;
								padding: 5px 15px;

								img {
									float: left;
									width: 16px;
									margin-right: 10px;
								}
							}
						}
					}
				}
			}
		}
	}

	.nav-social {
		padding-left: 5px;

		@media (max-width:767px) {
			display: none;
		}

		a {
			font-size: 21px;
			padding: 5px;
		}
	}
}


.vo-nav-bar {

	a.nav-item {
		text-transform: uppercase;
		padding: 20px 10px;
		line-height: 83px;
		color: var(--bluecolor);

		@media (max-width:1240px) {
			font-size: 13px;
			padding: 20px 4px;
		}

		@media (max-width:1170px) {
			padding: 20px 10px;
		}

		@media (max-width:992px) {
			line-height: initial;
		}

		&:hover {
			text-decoration: none;
			color: var(--orangecolor);
		}
	}


	.icon {
		display: none;
	}

	.input-group {
		float: left;
	}

	.input-group {
		padding: 9px 16px;
	}


	.vo-dropdown-content {
		display: none;
		position: absolute;
		background-color: var(--whiteColor);
		min-width: 205px;
		box-shadow: 0px 2px 7px 0px rgba(0, 0, 0, 0.1);
		z-index: 9;
	}

	.vo-dropdown:hover .vo-dropdown-content {
		display: block;
	}


	@media screen and (max-width: var(--navbarbreak)) {

		a:not(:first-child),
		.input-group,
		.vo-dropdown .dropbtn {
			display: none;
		}

		a.icon {
			width: 30px;
			height: 30px;
			display: flex !important;
			align-items: center;
			justify-content: center;
			flex-wrap: wrap;
			margin-right: 5px;
		}
	}


	@media (min-width:var(--navbarbreak)) {
		.search {
			width: 150px;
			transition: width .3s ease-in-out;
			-webkit-transition: width .3s ease-in-out;
			-moz-transition: width .3s ease-in-out;
			-ms-transition: width .3s ease-in-out;
		}

		.search:focus-within {
			width: 250px;
		}

		.rightme {
			float: right;
		}

	}

}


@media screen and (max-width: var(--navbarbreak)) {

	.vo-nav-bar.opened a.toggle-icon {
		float: right;
	}

	.vo-nav-bar.opened .input-group {
		display: table;
	}

	.vo-nav-bar.opened .vo-dropdown {
		float: none;
	}

	.vo-nav-bar.opened .navbar-area {
		@media (max-width:992px) {
			visibility: visible;
			opacity: 1;
			box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
			top: 68px;
		}
	}

	.vo-nav-bar.opened .vo-dropdown .dropbtn {
		display: block;
		width: 100%;
		text-align: left;
	}
}