.inner-page-title {
	padding-bottom: 9px;
	border-bottom: 1px solid var(--greyColor);
	margin-bottom: 20px;

	h1 {
		margin-bottom: 5px;
	}
}

.gallery {
	display: block;
	position: relative;
	background: none;
	overflow: hidden;
	-webkit-transition: all .3s ease-out;
	transition: all .3s ease-out;
	padding: 0;

	img {
		padding: 5px 8px;
		display: block;
		position: relative;
		-webkit-transition: all .3s ease-out;
		transition: all .3s ease-out;
		-webkit-backface-visibility: hidden;
		backface-visibility: hidden
	}

	&:hover {
		img {
			-webkit-transform: scale(1.1);
			transform: scale(1.1);
			filter: alpha(opacity=40);
			-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=40)";
			-moz-opacity: .4;
			-khtml-opacity: .4;
			opacity: .4
		}
	}
}


.map-pane {
	.map-main {
		height: 100%;
		padding-bottom: 0;

		>div {
			height: 100%;

			.flex {
				height: 100%;
			}
		}
	}
}

.contact-map-section {
	margin-top: 70px;

	.contact-map {
		#map {
			height: 550px !important;
		}
	}

}

#map {
	margin-top: 0;

	.mapnote {
		margin: 0 0 15px 0;
		font-size: 14px;
		padding-left: 15px;
	}

	.mapconsent {
		background: rgba(26, 121, 187, .15);
		padding: 15px;
		height: 550px;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		text-align: center;

		a {
			background: var(--greenColor);
			color: #fff;
			border-radius: 5px;
			padding: 0 15px;
			height: 36px;
			display: inline-block;
			line-height: 35px;
			margin: 0 0 15px 0;

			&:hover {
				background: var(--skyBlueColor);
			}
		}
	}
}

.contact-page {
	label {
		font-weight: 400;
	}

	.doc-box {
		vertical-align: top;
	}

	.contact-info {
		background: var(--greyColor);
		margin: 0;
		padding: 25px 25px 10px;
		list-style-type: none;

		li {
			position: relative;
			padding-left: 30px;
			margin: 0 0 15px;

			i {
				position: absolute;
				left: 0;
				top: 6px;
			}
		}
	}
}