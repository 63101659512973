.blog-entry {
	display: block;
	text-decoration: none;
	margin-top: 20px;
	padding-top: 20px;
	border-top: 1px dotted #7c949f;
	color: var(--defaultColor);
}

.blog-detail {
	padding-top: 100px;
}