@import "font-awesome-custom.css";

@define-mixin flexlayout $direction {

	/* OLD - iOS 6-, Safari 3.1-6, BB7 */
	display: -webkit-box;
	/* TWEENER - IE 10 */
	display: -ms-flexbox;
	/* NEW - Safari 6.1+. iOS 7.1+, BB10 */
	display: -webkit-flex;
	/* NEW, Spec - Firefox, Chrome, Opera */
	display: flex;

	-webkit-flex-direction: $(direction);
	-ms-flex-direction: $(direction);
	flex-direction: $(direction);
}

.layout-column {
	@mixin flexlayout column;
}

.layout-row {
	@mixin flexlayout row;
}


@define-mixin flex {
	/* OLD - iOS 6-, Safari 3.1-6 */
	-webkit-box-flex: 1;
	/* Safari 6.1+. iOS 7.1+, BB10 */
	-webkit-flex: 1 1 0;
	-ms-flex: 1 1;
	flex: 1 1 0;

	min-width: 0;
	min-height: 0;
}

.flex {
	@mixin flex;
}


@define-mixin columnlayout $rows,
$gap: 1em {
	-webkit-column-count: $(rows);
	/* Chrome, Safari, Opera */
	-moz-column-count: $(rows);
	/* Firefox */
	column-count: $(rows);

	-webkit-column-gap: $(gap);
	/* Chrome, Safari, Opera */
	-moz-column-gap: $(gap);
	/* Firefox */
	column-gap: $(gap);
}


@define-mixin size100 {
	width: 100%;
	height: 100%;
}

.size100 {
	@mixin size100;
}

@define-mixin overflowscrolls {
	display: block;
	overflow: auto;
	position: relative;
	-webkit-overflow-scrolling: touch;
}

.overflowscrolls {
	@mixin overflowscrolls;
}


.text-truncate {
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

.doc-box {
	text-align: left;
}

.hidden {
	display: none;
}

.clean-form {
	select {
		-moz-appearance: none;
		-webkit-appearance: none;
	}
}

.grey-bg {
	border-top: 1px solid #eaebec;
	border-bottom: 1px solid #eaebec;
	background: #F7F8FA;
}

.glyph {
	width: 26px;
	height: 26px;
	display: inline-block;
}

.glyph.i-door {
	background: no-repeat url(RESOURCE/img/door.png) 0 0;
}

.confirmation {

	.fa-check,
	h1 {
		color: green;
	}
}

.list-striped li:nth-of-type(odd) {
	background-color: #f9f9f9;
}

.list-striped-dark li:nth-of-type(odd) {
	background-color: #dadada;
}

.pano {
	min-height: 400px;
	margin-bottom: 100px !important;
}


.slider.slider-horizontal {
	width: 100%;
}


.contact-form {
	label {
		font-weight: normal;
	}

	label.lrequired {
		font-weight: 700;
	}

	margin-bottom: 20px;
}

.invalid {
	text-decoration: line-through;
}

.special {
	color: red;
}

#swipebox-prev,
#swipebox-next,
#swipebox-close {
	background-image: url(RESOURCE/img/icons.png) !imortant;
	background-repeat: no-repeat;
	border: none !important;
	text-decoration: none !important;
	cursor: pointer;
	width: 50px;
	height: 50px;
	top: 0;
}

.asd__day--tada {
	background-color: red;
}

/* date picker */
.datepicker-trigger {
	--cal_not_available: #cd7898;

	.asd__day--arrival {
		background: #ff932c;
		background: linear-gradient(135deg, var(--greenColor) 50%, #dedede 0) !important;
		opacity: 1 !important;
	}

	.asd__day--departure {
		background: #ff932c;
		background: linear-gradient(135deg, #dedede 50%, var(--greenColor) 0) !important;
		opacity: 1 !important;
	}

	.asd__day--arrival:hover:not(.asd__day--disabled):not(.asd__day--selected) {
		background-color: var(-hover-dt);
	}

	.asd__day--departure:hover:not(.asd__day--disabled):not(.asd__day--selected) {
		background-color: var(-hover-dt);
	}

	.asd__day--disabled.asd__day--in-range {
		background: #BAD1E9 !important;
		opacity: 1 !important;
	}

	.asd__day--disabled.asd__day--hovered {
		background: rgb(103, 246, 238) !important;
		opacity: 1 !important;
	}

	.asd__day--disabled.asd__day--arrival:not(.asd__day--selected),
	.asd__day--disabled.asd__day--departure:not(.asd__day--selected) {
		background-color: var(--greenColor);
		cursor: pointer;
		color: var(--whiteColor) !important;

		color:var(--whiteColor) button {
			text-decoration: underline;
		}
	}

	.asd__day--enabled {
		background-color: var(--greenColor);
		cursor: pointer;
		color: var(--whiteColor) !important;


		button {
			text-decoration: none;
		}

		&:hover {
			background-color: var(--defaultColor);
		}

		&.asd__day--hovered {
			background: #bad1e9 !important;
			opacity: 1 !important;
			color: var(--black) !important;
		}

		&.asd__day--in-range {
			color: var(--black) !important;
		}


		&.asd__day--disabled {
			color: var(--black) !important;

			button {
				text-decoration: none;
				cursor: not-allowed;
			}

		}

		&.asd__day--not-available {
			background: #dedede !important;
			opacity: 1 !important;
			cursor: not-allowed;
			color: var(--whiteColor) !important;
		}
	}

	.asd__day--selected {
		background-color: var(--hover-dt) !important;
		color: white !important;
		opacity: 1 !important;


	}

	.asd__day--in-range {
		background: #BAD1E9 !important;
	}

	.asd__selected-date-one {
		background-image: url(RESOURCE/img/date-start.png) !important;
		background-repeat: no-repeat !important;
		background-size: 13px !important;
		background-position: right center !important;
		color: var(--black) !important;
	}

	.asd__selected-date-two {
		background-image: url(RESOURCE/img/date-end.png) !important;
		background-repeat: no-repeat !important;
		background-size: 13px !important;
		background-position: left center !important;
		color: var(--black) !important;
	}

	.asd__day {
		border: 1px solid #CCCCCC !important;
	}
}



/*
.pswp {
	display: none;
}
*/

.app-loaded #preloader {
	display: none;
}

.app-leaving #preloader {
	display: block;
	opacity: 0.6;
}



/* Start Custom Css*/

body {
	font-family: 'Roboto', sans-serif;
	color: var(--defaultColor);
	font-size: 16px;
	background-color: #f6f6f6;
}

b,
strong {
	font-weight: 700;
}

.h1,
.h2,
.h3,
h1,
h2,
h3,
h4,
h5 {
	font-weight: 500;
	margin-top: 0;
	margin-bottom: 20px;
	color: var(--greenColor);
}

h1,
.h1 {
	font-size: 36px;

	@media (max-width: 767px) {
		font-size: 30px;
	}
}

h2,
.h2 {
	font-size: 30px;

	@media (max-width: 767px) {
		font-size: 24px;
	}
}

h3,
.h3 {
	font-size: 24px;

	@media (max-width: 767px) {
		font-size: 18px;
	}
}

h4,
.h4 {
	@media (max-width: 767px) {
		font-size: 16px;
	}
}

p {
	margin-bottom: 12px;

	&:last-child {
		margin-bottom: 0;
	}
}

.green-line {
	border-top: 1px dotted #90bf34;
	margin: 25px 0;
}

a {
	text-decoration: none;
	color: var(--greenColor);
	transition: all ease 0.3s;

	&:hover,
	&:focus,
	&.active a {
		text-decoration: none;
		outline: 0 none;
		color: var(--defaultColor);
	}
}

.btn {
	background: var(--greenColor);
	color: var(--defaultColor);
	border-radius: 0px;
	border: 1px solid #999;
	box-shadow: none;
	transition: all ease 0.3s;
	padding: 6px 12px;

	@media (max-width:767px) {
		white-space: inherit;
	}

	.fas {
		vertical-align: middle;
		margin-left: 2px;
	}

	&:hover,
	&.focus,
	&.active,
	&:active,
	&:focus,
	&:active:focus {
		background: #e6e6e6;
		color: var(--defaultColor);
		outline: 0 none;
		box-shadow: none;
		border-color: #999;
	}

	&.btn-sm {
		padding: 5px 15px;
	}

	&.btn-md {
		padding: 10px 20px;
	}

	&.btn-lg {
		padding: 15px 25px;
		font-size: 16px;
	}

	&.btn-white {
		background: var(--whiteColor);
		color: var(--defaultColor);

		&:hover {
			background: #e6e6e6;
			color: var(--defaultColor);
		}
	}
}

ul.list-style {
	margin-bottom: 25px;

	li {
		position: relative;
		margin: 10px 0;
		padding-left: 20px;
		color: var(--greenColor);

		&:before {
			content: "";
			background: var(--greenColor);
			width: 5px;
			height: 5px;
			border-radius: 100%;
			position: absolute;
			left: 0;
			top: 9px;
		}
	}

	&.large-style {
		li {
			font-size: 24px;
			margin: 15px 0;

			&:before {
				top: 17px;
			}
		}
	}
}

.gren-dashed-box {
	border: 3px dashed var(--greenColor);
	padding: 20px;
}

.grey-bg {
	background: var(--greyColor);
}

.white-bg {
	background: var(--whiteColor);
}

.white-text * {
	color: var(--whiteColor);
}

.bg-image-section {
	background-size: cover;
	background-attachment: fixed;
	background-position: center top;
	position: relative;
}

.form-control {
	border-radius: 0;
	box-shadow: none;
	border-color: #999;

	&.form-control-lg {
		font-size: 16px;
		padding: 14px 1rem;
		height: inherit !important;
	}

	&.btn-lg {
		padding: 14px 1rem;
		font-size: 16px;
	}
}

.brief {
	padding-top: 50px;
	padding-bottom: 50px;

	@media (max-width:991px) {
		padding-top: 30px;
		padding-bottom: 30px;
	}
}

.unitListBox {
	overflow: hidden;
	border: 1px solid var(--greyColor);
	background: var(--greyColor);

	.unitImg {
		position: relative;
		overflow: hidden;

		img {
			transition: all ease 0.3s;
		}

		&:hover {
			img {
				-webkit-transform: scale(1.1);
				transform: scale(1.1);
				filter: alpha(opacity=40);
				-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=40)";
				-moz-opacity: .4;
				-khtml-opacity: .4;
				opacity: .4
			}
		}

		.fav-icon {
			position: absolute;
			top: 0;
			right: 0;
			color: var(--whiteColor);
			background: var(--greenColor);
			padding: 4px;
			font-size: 12px;
			font-weight: 600;
			text-align: center;
			width: 25px;
			height: 25px;
		}

		.room-size {
			position: absolute;
			top: 0;
			left: 0;
			color: var(--whiteColor);
			background: var(--defaultColor);
			padding: 4px;
			font-size: 13px;
			border: 1px solid var(--whiteColor);
		}
	}

	.title-dtl {

		p {
			font-size: 13px;
			overflow: hidden;
			height: 55px;
		}

		.price {
			float: left;
			background: #fff;
			padding: 2px 10px;
			border-radius: 3px 3px 0 0;
			margin-top: 10px;
			font-weight: 600;
			color: var(--defaultColor);

			span {
				font-weight: 400;
				font-size: 10px;
				margin-right: 3px;
			}
		}
	}
}

.unit-box {
	border: 1px solid var(--whiteColor);
	position: relative;
	margin-bottom: 15px;

	.rent-rate {
		position: absolute;
		top: 60px;
		z-index: 1;
		right: 0;
		background-color: var(--skyBlueColor);
		padding: 5px 10px;
		color: var(--whiteColor);
		font-weight: 700;
	}

	.unit-title {
		color: var(--whiteColor);
		padding: 12px 10px;
		text-align: center;
		background-color: rgba(144, 191, 52, .8);
		position: absolute;
		top: 0;
		width: 100%;
		z-index: 2;
		font-size: 16px;
	}

	.img-content {
		position: relative;
		overflow: hidden;

		img {
			transition: all ease 0.3s;
		}

		&:hover {
			img {
				-webkit-transform: scale(1.1);
				transform: scale(1.1);
				filter: alpha(opacity=40);
				-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=40)";
				-moz-opacity: .4;
				-khtml-opacity: .4;
				opacity: .4
			}
		}

		.fav-icon {
			position: absolute;
			left: 5px;
			bottom: 5px;
			color: var(--greenColor);
		}

		.map-marker {
			position: absolute;
			bottom: 5px;
			top: inherit;
			right: 5px;

			i {
				color: var(--skyBlueColor);
				font-size: 24px;
			}
		}

		img {
			width: 100%;
		}
	}

	.unit-cont-bl {
		background-color: var(--whiteColor);
		padding: 10px 15px;
		height: 63px;
		overflow: hidden;
	}

	.offer-angebote {
		background: #32beef;
		color: #fff;
		padding: 10px 15px;
	}

	.search-result-properties {
		display: flex;
		text-align: center;
		justify-content: center;
		background-color: var(--skyBlueColor);

		.ft-icon {
			text-align: center;
			display: inline-flex;
			align-items: center;
			color: var(--whiteColor);
			border: 1px solid var(--whiteColor);
			padding: 5px 10px;
			margin: 10px 5px;

			.number {
				margin-left: 5px;
			}
		}
	}
}

.innerBanner {
	min-height: 300px;
	background: var(--searchPageBanner) no-repeat center top;
	-webkit-background-size: cover;
	background-size: cover;
	position: relative;

	@media (max-width: 991px) {
		min-height: 200px;
	}

	@media (max-width: 767px) {
		min-height: 150px;
	}

	&.staticBanner {
		min-height: 340px;

		&.package-deals-banner {
			background-image: var(--innerBanner);
		}
	}

	.innerContent {
		position: absolute;
		bottom: 0px;
		left: 0;
		right: 0;
		margin: 0 auto;
	}

	.searchbar {
		padding-top: 20px;
		padding-bottom: 20px;
		z-index: 9;
		background: rgba(255, 255, 255, 0.7);

		.vacation-search-link {
			color: #333;
			font-size: 21px;
		}
	}
}

ul {
	margin: 0;
	padding: 0;
	list-style-type: none;
}

input[type=checkbox]:focus,
input[type=file]:focus,
input[type=radio]:focus {
	outline: 0 none;
}

.checkbox input[type=checkbox]:focus+label:before,
body .checkbox input[type=checkbox]:focus+label:before {
	outline: 0 none;
}

.checkbox {
	padding-left: 0 !important;
}

:focus,
button:focus {
	outline: 0 none;
}

iframe {
	max-width: 100%;
	width: 100%;
	border: 0;
}

.min-height-800 iframe {
	min-height: 800px;
}

.icon-block {
	.icon {

		.fa,
		.fas,
		.far,
		.fab {
			font-size: 40px;
			line-height: 50px;
			color: var(--greenColor);
		}
	}

	p {
		a {
			color: var(--greenColor);
			font-size: 13px;
			padding-left: 10px;

			@media (max-width: 767px) {
				padding-left: 0;
			}
		}
	}
}

.asd__wrapper--full-screen {
	z-index: 99 !important;
}

.box {
	z-index: 9 !important;
}


.box {
	&.full-xxs {
		&.show {
			@media (max-width:480px) {
				padding-top: 70px !important;
			}
		}
	}
}

.green-color {
	color: var(--greenColor);
}

.fa-star,
.fa-star-o {
	color: var(--yellowColor) !important;
}


.owl-carousel-fs {
	.owl-stage-outer {
		min-height: 400px;

		@media all and (max-width:767px) {
			min-height: 200px;
		}
	}
}

.owl-nav {

	button {
		width: 50px;
		height: 60px;
		text-align: center;
		position: absolute;
		top: 50%;
		margin-top: -30px;

		@media (max-width:992px) {
			width: 60px;
		}

		&:before {
			font-size: 40px;
			color: var(--whiteColor);
			font-family: 'Font Awesome 5 Free';
			font-weight: 900;
		}

		span {
			display: none;
		}

		&.owl-prev {
			left: 0;

			&:before {
				content: "\f104";
			}
		}

		&.owl-next {
			right: 0;

			&:before {
				content: "\f105";
			}
		}
	}
}

.owl-thumbs {
	.owl-thumb-item {
		border: none;
		outline: 0 none;
		margin: 0;
		padding: 0;
		float: left;
		margin: 5px 5px 5px 0;

		&.active {
			img {
				opacity: 0.5 !important;
			}
		}
	}



	.userimg {
		width: 68px;
		height: 68px;
		overflow: hidden;
		display: inline-block;
		vertical-align: top;
		position: relative;

		img {
			transform: translate(-50%, -50%);
			position: absolute;
			left: 50%;
			top: 50%;
		}
	}
}

@font-face {
	font-family: 'Roboto';
	src: url('RESOURCE/fonts/Roboto-Regular.eot');
	src: url('RESOURCE/fonts/Roboto-Regular.eot?#iefix') format('embedded-opentype'),
		url('RESOURCE/fonts/Roboto-Regular.woff2') format('woff2'),
		url('RESOURCE/fonts/Roboto-Regular.woff') format('woff'),
		url('RESOURCE/fonts/Roboto-Regular.ttf') format('truetype'),
		url('RESOURCE/fonts/Roboto-Regular.svg#Roboto-Regular') format('svg');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Roboto';
	src: url('RESOURCE/fonts/Roboto-Bold.eot');
	src: url('RESOURCE/fonts/Roboto-Bold.eot?#iefix') format('embedded-opentype'),
		url('RESOURCE/fonts/Roboto-Bold.woff2') format('woff2'),
		url('RESOURCE/fonts/Roboto-Bold.woff') format('woff'),
		url('RESOURCE/fonts/Roboto-Bold.ttf') format('truetype'),
		url('RESOURCE/fonts/Roboto-Bold.svg#Roboto-Bold') format('svg');
	font-weight: Bold;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Roboto';
	src: url('RESOURCE/fonts/Roboto-Medium.eot');
	src: url('RESOURCE/fonts/Roboto-Medium.eot?#iefix') format('embedded-opentype'),
		url('RESOURCE/fonts/Roboto-Medium.woff2') format('woff2'),
		url('RESOURCE/fonts/Roboto-Medium.woff') format('woff'),
		url('RESOURCE/fonts/Roboto-Medium.ttf') format('truetype'),
		url('RESOURCE/fonts/Roboto-Medium.svg#Roboto-Medium') format('svg');
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Roboto';
	src: url('RESOURCE/fonts/Roboto-Thin.eot');
	src: url('RESOURCE/fonts/Roboto-Thin.eot?#iefix') format('embedded-opentype'),
		url('RESOURCE/fonts/Roboto-Thin.woff2') format('woff2'),
		url('RESOURCE/fonts/Roboto-Thin.woff') format('woff'),
		url('RESOURCE/fonts/Roboto-Thin.ttf') format('truetype'),
		url('RESOURCE/fonts/Roboto-Thin.svg#Roboto-Thin') format('svg');
	font-weight: 100;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Roboto';
	src: url('RESOURCE/fonts/Roboto-Black.eot');
	src: url('RESOURCE/fonts/Roboto-Black.eot?#iefix') format('embedded-opentype'),
		url('RESOURCE/fonts/Roboto-Black.woff2') format('woff2'),
		url('RESOURCE/fonts/Roboto-Black.woff') format('woff'),
		url('RESOURCE/fonts/Roboto-Black.ttf') format('truetype'),
		url('RESOURCE/fonts/Roboto-Black.svg#Roboto-Black') format('svg');
	font-weight: 900;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Roboto';
	src: url('RESOURCE/fonts/Roboto-Light.eot');
	src: url('RESOURCE/fonts/Roboto-Light.eot?#iefix') format('embedded-opentype'),
		url('RESOURCE/fonts/Roboto-Light.woff2') format('woff2'),
		url('RESOURCE/fonts/Roboto-Light.woff') format('woff'),
		url('RESOURCE/fonts/Roboto-Light.ttf') format('truetype'),
		url('RESOURCE/fonts/Roboto-Light.svg#Roboto-Light') format('svg');
	font-weight: 300;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Indie Flower';
	src: url('RESOURCE/fonts/IndieFlower.eot');
	src: url('RESOURCE/fonts/IndieFlower.eot?#iefix') format('embedded-opentype'),
		url('RESOURCE/fonts/IndieFlower.woff2') format('woff2'),
		url('RESOURCE/fonts/IndieFlower.woff') format('woff'),
		url('RESOURCE/fonts/IndieFlower.ttf') format('truetype'),
		url('RESOURCE/fonts/IndieFlower.svg#IndieFlower') format('svg');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

/* @font-face {
	font-family: 'FontAwesome';
	src: url('RESOURCE/fonts/fontawesome-webfont.eot');
	src: url('RESOURCE/fonts/fontawesome-webfont.eot') format('embedded-opentype'),
		url('RESOURCE/fonts/fontawesome-webfont.woff2') format('woff2'),
		url('RESOURCE/fonts/fontawesome-webfont.woff') format('woff'),
		url('RESOURCE/fonts/fontawesome-webfont.ttf') format('truetype'),
		url('RESOURCE/fonts/fontawesome-webfont.svg') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Font Awesome 5 Pro';
	font-style: normal;
	font-weight: 300;
	font-display: block;
	src: url("RESOURCE/fonts/fa-light-300.eot");
	src: url("RESOURCE/fonts/fa-light-300.eot?#iefix") format("embedded-opentype"),
		url("RESOURCE/fonts/fa-light-300.woff2") format("woff2"),
		url("RESOURCE/fonts/fa-light-300.woff") format("woff"),
		url("RESOURCE/fonts/fa-light-300.ttf") format("truetype"),
		url("RESOURCE/fonts/fa-light-300.svg#fontawesome") format("svg");
}

@font-face {
	font-family: 'Font Awesome 5 Pro';
	font-style: normal;
	font-weight: 400;
	font-display: block;
	src: url("RESOURCE/fonts/fa-regular-400.eot");
	src: url("RESOURCE/fonts/fa-regular-400.eot?#iefix") format("embedded-opentype"),
		url("RESOURCE/fonts/fa-regular-400.woff2") format("woff2"),
		url("RESOURCE/fonts/fa-regular-400.woff") format("woff"),
		url("RESOURCE/fonts/fa-regular-400.ttf") format("truetype"),
		url("RESOURCE/fonts/fa-regular-400.svg#fontawesome") format("svg");
}

@font-face {
	font-family: 'Font Awesome 5 Pro';
	font-style: normal;
	font-weight: 400;
	font-display: block;
	src: url("RESOURCE/fonts/fa-regular-400.eot");
	src: url("RESOURCE/fonts/fa-regular-400.eot?#iefix") format("embedded-opentype"),
		url("RESOURCE/fonts/fa-regular-400.woff2") format("woff2"),
		url("RESOURCE/fonts/fa-regular-400.woff") format("woff"),
		url("RESOURCE/fonts/fa-regular-400.ttf") format("truetype"),
		url("RESOURCE/fonts/fa-regular-400.svg#fontawesome") format("svg");
} */

@font-face {
	font-family: 'Font Awesome 5 Free';
	font-style: normal;
	font-weight: 400;
	font-display: block;
	src: url("RESOURCE/fonts/fa-regular-400.eot");
	src: url("RESOURCE/fonts/fa-regular-400.eot?#iefix") format("embedded-opentype"),
		url("RESOURCE/fonts/fa-regular-400.woff2") format("woff2"),
		url("RESOURCE/fonts/fa-regular-400.woff") format("woff"),
		url("RESOURCE/fonts/fa-regular-400.ttf") format("truetype"),
		url("RESOURCE/fonts/fa-regular-400.svg#fontawesome") format("svg");
}

.fa,
.far,
.fas {
	font-family: "Font Awesome 5 Free";
}

@font-face {
	font-family: 'Font Awesome 5 Brands';
	font-style: normal;
	font-weight: 400;
	font-display: block;
	src: url("RESOURCE/fonts/fa-brands-400.eot");
	src: url("RESOURCE/fonts/fa-brands-400.eot?#iefix") format("embedded-opentype"),
		url("RESOURCE/fonts/fa-brands-400.woff2") format("woff2"),
		url("RESOURCE/fonts/fa-brands-400.woff") format("woff"),
		url("RESOURCE/fonts/fa-brands-400.ttf") format("truetype"),
		url("RESOURCE/fonts/fa-brands-400.svg#fontawesome") format("svg");
}

.fab {
	font-family: 'Font Awesome 5 Brands';
}


@font-face {
	font-family: 'Font Awesome 5 Free';
	font-style: normal;
	font-weight: 900;
	font-display: block;
	src: url("RESOURCE/fonts/fa-solid-900.eot");
	src: url("RESOURCE/fonts/fa-solid-900.eot?#iefix") format("embedded-opentype"),
		url("RESOURCE/fonts/fa-solid-900.woff2") format("woff2"),
		url("RESOURCE/fonts/fa-solid-900.woff") format("woff"),
		url("RESOURCE/fonts/fa-solid-900.ttf") format("truetype"),
		url("RESOURCE/fonts/fa-solid-900.svg#fontawesome") format("svg");
}

.fa,
.fas {
	font-family: 'Font Awesome 5 Free';
	font-weight: 900;
}

@media (max-width: 991px) {
	.position-md-static {
		position: static;
	}
}

@media (max-width: 767px) {
	.asd__mobile-only {
		display: block !important;
	}
}