@import "setup.css";

.indexpage {

	margin-top: 68px;

	.subscribe-box {
		.form-control {
			margin: 10px 0 10px 0;
		}
	}

	.iheader {
		position: relative;

		.home-banner-slide {
			position: relative;

			&.home-video {
				iframe {
					max-width: 100%;
					height: 700px;
					float: left;

					@media (max-width:991px) {
						height: 500px;
					}

					@media (max-width:767px) {
						height: 300px;
					}
				}

				.cover-video-bl {
					height: calc(100vh - 68px);
					overflow: hidden;
					background-repeat: no-repeat;
					background-size: cover;
					background-position: center center;
					position: relative;

					@media (max-width:767px) {
						content: "";
						width: 100%;
						bottom: 0;
						height: 8px;
						background: rgba(0, 0, 0, .2);
						left: 0;

					}

					.video {
						max-width: 100%;
						width: 100%;
						position: relative;
						transform: translateY(-50%);
						top: 50%;
						background-size: cover;
						background-position: center;

						@media (max-width: 1370px) {
							top: 0;
							transform: translateY(0);
						}
					}

					@media (max-width: 1370px) {
						height: auto;
					}

				}
			}

			.item {
				background-repeat: no-repeat;
				background-position: center top;
				-webkit-background-size: cover;
				background-size: cover;
				min-height: 400px;

				@media (min-width:992px) {
					min-height: 800px;
				}

				&.slide1 {
					background-image: var(--homeBanner1);
				}

				&.slide2 {
					background-image: var(--homeBanner2);
				}

				&.slide3 {
					background-image: var(--homeBanner3);
				}

				.slider-caption {
					color: var(--whiteColor);
					text-shadow: 0 0 3px #333;
					font-weight: 700;
					font-size: 42px;
					padding-top: 220px;

					@media (max-width: 991px) {
						padding-top: 100px;
						font-size: 32px;
					}
				}
			}

			.owl-dots {
				position: absolute;
				right: 10px;
				top: 45%;
				width: 10px;

				.owl-dot {
					background: transparent;
					width: 10px;
					height: 10px;
					float: left;
					margin: 5px 0;
					border-radius: 100%;
					border: 1px solid var(--whiteColor);

					&.active {
						background: var(--whiteColor);
					}
				}
			}
		}
	}

	.searchbar {

		position: absolute;
		background: rgba(255, 255, 255, 0.6);
		width: 100%;
		bottom: 60px;
		padding-top: 20px;
		padding-bottom: 20px;
		z-index: 9;

		.vacation-search-link {
			color: #333;
			font-size: 21px;
		}

		h1 {
			color: var(--defaultColor);
			margin: 0;
			font-weight: 300;
			margin-top: 30px;
		}

		.searchbox {

			.search-popup-trigger {
				cursor: pointer;
			}

			.form-control:disabled,
			.form-control[readonly] {
				background-color: white;
			}

		}

		@media (max-width: 991px) {
			bottom: 0;
		}

		@media (max-width: 767px) {
			position: static;
			background: rgba(0, 0, 0, 0.2);
		}

	}

	@media (max-width:767px) {

		.iheader {
			background: var(--introMobileImg) no-repeat center;
			-webkit-background-size: cover;
			background-size: cover;

			.intro {
				.hero {
					h2 {
						font-size: 18px;
					}
				}
			}


		}
	}






}