.main-footer {

	.footer-weather {
		background: var(--skyBlueColor);

		h3 {
			color: var(--whiteColor);
			margin: 0;
		}
	}

	.footer-cta {
		background: var(--greenColor);
		padding: 10px 0;
		border-top: 10px solid var(--yellowColor);

		a {
			color: var(--whiteColor);
		}

		.cta-booking {
			a {
				font-size: 24px;
			}
		}

	}

	.footer-top {
		padding: 20px 0;
		background: var(--greenColor);

		.row {
			.col-md-3 {
				h4 {
					color: var(--whiteColor);
					font-weight: 400;
					font-size: 18px;
					margin-top: 15px;
				}

				p {
					color: var(--whiteColor);
				}
			}
		}


		.footer-links {
			a {
				color: var(--whiteColor);
				border-right: 1px solid #fff;
				line-height: 18px;
				padding-right: 8px;
				margin-right: 5px;
				display: inline-block;
				vertical-align: top;
			}
		}

		.copyrights {
			p {
				line-height: 18px;
				color: var(--whiteColor);
			}
		}

		.footer-social {
			a {
				font-size: 20px;
				margin: 0 10px 0 0;
				color: var(--whiteColor);
			}
		}

		@media (max-width: 767px) {
			text-align: center;
		}

		.about {
			padding: 0 0 30px 0;

			@media (max-width: 767px) {
				padding: 0 0 15px 0;
			}

			a {
				font-size: 28px;
				color: var(--bluecolor);

				@media (max-width: 767px) {
					font-size: 26px;
				}
			}

			.img-responsive {
				max-width: 250px;

				@media (max-width: 991px) {
					max-width: 100%;
				}

				@media (max-width: 767px) {
					max-width: 250px;
					margin: 0 auto;
				}
			}
		}

		h3 {
			color: var(--whiteColor);
			margin: 0 0 5px;
			padding: 0;
			text-transform: uppercase;
			font-size: 16px;
			font-weight: 700;
		}

		.useful_links {
			padding: 0;
			list-style: none;
			margin: 0 0 30px 0;

			li {
				padding: 5px 0;
				margin: 0;

				a {
					font-weight: 300;
					color: var(--defaultColor);

					&:hover {
						color: var(--greenColor);
					}
				}
			}
		}

		.footer-contact {
			p {
				font-weight: 400;
				color: var(--whiteColor);
				line-height: 20px;
			}
		}

		.address_details {
			padding: 0;
			list-style: none;
			margin: 0;

			li {
				padding: 0;
				margin: 0;
				color: var(--whiteColor);

				i {
					margin-right: 7px;
				}

				a {
					color: var(--whiteColor);
					text-decoration: none;
					font-weight: 300;

					&:hover {
						color: var(--whiteColor);
					}
				}
			}
		}
	}

	.v-office {
		img {
			max-width: 120px;
		}
	}

	.contact-section {
		padding: 40px 10px 80px 10px;
		text-align: center;

		p {
			color: #555;
			margin-bottom: 20px;
		}

		p.lead {
			font-size: 24px;
			margin-bottom: 10px;
		}

		.contact {
			font-size: 24px;

			.fa {
				margin: 10px;
				font-size: 36px;
			}
		}
	}
}