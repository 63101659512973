@import "common.css";
@import "setup.css";

.vo-search-html {
	min-height: 100%;
	height: 100%;
}





.map-view {
	.map-pane .map-main>.flex {
		height: calc(100vh - 192px);

		@media (max-width: 1150px) {
			height: calc(100vh - 204px);
		}

		@media (max-width: 890px) {
			height: calc(100vh - 234px);
		}

		@media (max-width: 768px) {
			height: calc(100vh - 254px);
		}

		@media (max-width: 460px) {
			height: calc(100vh - 302px);
		}

		@media (max-width: 360px) {
			height: calc(100vh - 366px);
		}
	}
}

.list-view {
	.map-pane .map-main>.flex {
		height: calc(100vh - 214px);

		@media (max-width: 1150px) {
			height: calc(100vh - 264px);
		}

		@media (max-width: 988px) {
			height: calc(100vh - 280px);
		}

		@media (max-width: 890px) {
			height: calc(100vh - 277px);
		}

		@media (max-width: 855px) {
			height: calc(100vh - 302px);
		}


	}
}

.map-pane {

	&.layout-column {
		@media (min-width:993px) {
			height: 100%;
		}

		@media (max-width:992px) {

			margin-top: 15px;
		}



		.no-map {
			height: 100%;

			.mapconsent {}


		}
	}



	.mapnote {
		padding: 0 20px 20px;



	}

	.map-main {
		>.flex {
			width: 100%;


			>div {
				height: 100%;
			}
		}

		.leaflet-popup-content {
			margin: 0;
			line-height: 1.4;
			padding: 15px;
			width: 290px !important;
		}
	}


	.no-map {


		>.flex {
			height: 100% !important;

			>div {
				height: 100%;

				.mapconsent {

					display: flex;
					align-items: center;
					justify-content: center;
					text-align: center;
					background: rgba(26, 121, 187, 0.15);
					flex-direction: column;
					padding: 0 15px;
					height: 100%;

					.mapconsent-button {
						background: var(--greenColor);
						color: #fff;
						border-radius: 5px;
						padding: 0 15px 0 15px;
						height: 36px;
						display: block;
						line-height: 35px;

						&:hover {
							background: var(--skyBlueColor);
						}
					}
				}
			}
		}

	}

}

.vo-search-body {
	min-height: 100%;
	height: 100%;
	@mixin flexlayout column;

	.result-pane {
		width: 100%;
		padding: 3px;

		color: #565a5c;

		a {
			color: inherit;
		}

		.a-color {
			color: #007da7;
		}

		.result-header {
			padding: 0 5px;
			border-bottom: 1px solid rgb(235, 235, 235);
			margin-bottom: 15px;

			.sorting-trigger {
				cursor: pointer;
			}

			.mysearch {
				margin-bottom: 4px;
			}
		}

		.pagination-panel {
			text-align: center;
		}

	}

	.map-view {
		.result-pane {
			display: none;
		}
	}

	.map-pane {
		/* 		
     background-color: #f5f5f5;
     */

		.header {
			display: none;
			padding: 5px;
			background-color: white;

			.close {
				font-size: 46px;
				font-weight: normal;
			}
		}
	}

	.map-unit-preview {
		color: #565a5c;
		text-align: center;

		a {
			color: inherit;
		}

		.prices {
			font-size: 14px;
			margin-top: 6px;
			font-weight: 400;
		}

		.preview-img {
			min-height: 150px;

			img {
				max-width: 100%;
				height: 150px;
				width: 100%;
				margin: 0 auto;
				margin-bottom: 15px;
			}
		}

		.lazy-preview-placeholder {
			height: 350px;
			width: 240px;
		}

	}


	.results,
	.spinner-panel,
	.more-btn-panel {
		background-color: #f5f5f5;
	}

	.more-btn-panel {
		padding-bottom: 40px;
	}

	.spinner-panel {
		padding-bottom: 20px;
	}

	.results {

		padding: 0;

		.no-search-results {
			margin-top: 20px;
			margin-bottom: 27px;
			width: 100%;
			text-align: center;
		}

		.alternatives {
			span {
				font-size: 14px;
				margin-bottom: 10px;
				display: inline-block;
				vertical-align: top;
			}
		}

		.altheading {
			color: red;
			margin: 0 0 7px;
		}

		h4 {
			margin-top: 0;
			padding-right: 45px;

			@media (max-width:767px) {
				margin-top: 15px;
			}
		}

		.srow {
			width: 100%;
			position: relative;

			padding: 5px;
			padding-bottom: 15px;

			.offer-box {
				background-color: #ecf7fd;
				position: relative;
				padding: 10px 20px;
				display: -webkit-inline-box;
				display: inline-flex;
				-webkit-box-align: center;
				align-items: center;
				background-repeat: no-repeat;
				background-position: 50%;
				background-size: 413px;

				@media (max-width:992px) {
					display: block !important;
					text-align: center;
				}

				.offer-left {
					background-image: url(RESOURCE/img/bg-offer-text.png);
					background-repeat: no-repeat;
					background-position: 50%;
					font-size: 13px;
					width: 116px;
					height: 38px;
					display: flex;
					align-items: center;
					justify-content: center;
					text-transform: uppercase;
					font-weight: 700;
					background-size: 116px;
					color: #363b3e;

					@media (max-width:992px) {
						display: inline-flex;
						margin-bottom: 10px;
					}
				}

				ul {
					padding: 0;
					list-style: none;
					margin: 3px 0 0;
					float: left;

					@media (max-width:767px) {
						width: 100%;
						margin: 10px 0 0;
					}

					li {
						font-size: 13px;
						color: #e09e46;
						font-weight: 700;
						padding-left: 0;
						text-align: left;
						list-style: disc;
						margin-left: 30px;

						@media (max-width:767px) {
							margin-left: 10px;
							margin-bottom: 5px;
						}
					}
				}
			}

			.map-marker {
				position: absolute;
				top: 0;
				right: 0;
				display: inline-block;
				width: 50px;
				height: 50px;
				text-align: center;

				@media (max-width: 767px) {
					display: none !important;
				}

				.fa-map-marker-alt {
					position: relative;
					top: 12px;
					cursor: pointer;
					font-size: 24px;
					color: #dedede;
				}

				.fa-map-marker-alt:hover,
				.fa-map-marker-alt.selected {
					color: #565a5c;
				}
			}

			.img-col {

				.img-content {

					.full-img {
						width: 100%;
						height: auto;

					}


					.fav-icon {
						position: absolute;
						cursor: pointer;
						right: 0;
						top: 0;
						color: #fff;
						margin: 3px;
						font-size: 24px;
						width: 35px;
						text-align: center;
						z-index: 9;
					}


				}


			}

			.info-col {
				position: relative;
				padding: 3px 3px 3px 6px;
				background-color: white;

				border-radius: 5px;
				box-shadow: 0 1px 3px rgba(0, 0, 0, .1);

				.stars {
					position: absolute;
					right: 0;
					bottom: 0;
				}

				.prices {
					text-align: right;
					padding-right: 20px;

					@media (max-width:768px) {
						text-align: left;
					}

					span {
						margin-bottom: 10px;
						display: inline-block;
						vertical-align: top;
						font-size: 18px;
					}

					span.main-price {
						text-decoration: line-through;
					}

					.price {
						margin-bottom: 10px;
						display: inline-block;
						vertical-align: top;
						font-size: 14px;
					}

				}

				.search-result-properties {
					margin-top: 5px;
					margin-bottom: 5px;
					font-size: 14px;

					@media (max-width:380px) {
						font-size: 12px;
					}
				}



			}
		}


	}


	.search-result-properties {
		display: inline-block;

		>div {
			float: left;
			text-align: center;
			padding: 3px;

			border-right: 1px solid #ccc;

			:last-child {
				font-weight: bold;
			}
		}

		>div:last-child {
			border: none;
		}

	}



	.search-bar {
		padding: 5px 10px;
		margin-top: 70px;

		.daterange {
			display: inline-block;
		}

		.btn {
			margin: 2px;
		}

		@media (max-width:768px) {
			.sm-full {
				display: block;

			}

			a {
				display: block;
			}
		}
	}

	@media (max-width:767px) {

		.list-view {
			.map-pane {
				display: none;
			}
		}

		.map-view {
			.map-pane {
				@mixin flex;
			}
		}


		.map-pane.full-screen {
			display: flex;
			position: fixed !important;
			left: 0px !important;
			right: 0px !important;
			bottom: 0px !important;
			top: 0px !important;

			.header {
				display: block;
			}

		}

		.pagination-panel {
			display: none;
		}

	}

	@media (min-width:768px) {
		.map-pane {
			@mixin flex;

		}

		.result-pane {
			width: 750px;
			max-width: 60%;

			.more-btn-panel {
				display: none;
			}


		}


		.results {
			.srow {
				@mixin flexlayout row;

				.info-col {
					width: 60%;
				}

				.img-col {
					@mixin flex;
					position: relative;

					.img-content {
						overflow: hidden;
						position: absolute;
						@mixin size100;


						.owl-carousel,
						.owl-stage-outer,
						.owl-stage,
						.owl-item,
						.item {
							height: 100%;
						}


						.full-img {
							height: 100%;
							object-fit: cover;

						}
					}

				}

			}
		}



	}





}