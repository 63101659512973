:root {
	--homeBanner1: url(RESOURCE/img/home-banner-1.jpg);
	--homeBanner2: url(RESOURCE/img/home-banner-2.jpg);
	--homeBanner3: url(RESOURCE/img/home-banner-3.jpg);
	--homeUnitBg: url(RESOURCE/img/home-unit-bg.jpg);
	--innerBanner: url(RESOURCE/img/inner-banner.jpg);
	--navbarbreak: 991px;
	--defaultColor: #333;
	--skyBlueColor: #32BEEF;
	--whiteColor: #fff;
	--greenColor: #90bf34;
	--yellowColor: #fc0;
	--greyColor: #eee;
}