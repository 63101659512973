@import "common.css";

.unit-view-section {
	padding-top: 65px;
	padding-bottom: 50px;

	.form-horizontal {
		.form-group {
			margin-right: -15px;
			margin-left: -15px;
		}
	}
}

.page-header {
	padding-bottom: 9px;
	margin: 0 0 20px;
	border-bottom: 1px solid #eee;

	h1 {
		margin: 0;
	}
}

.unit-view {
	.unit-slider {
		float: left;
		width: 100%;
		margin: 0 0 30px 0;

		.owl-nav {
			button.owl-prev {
				left: 30px;

				&:before {
					content: "\f053";
				}
			}

			button.owl-next {
				right: 30px;

				&:before {
					content: "\f054";
				}
			}

			button {
				width: 70px;
				height: 70px;
				border-radius: 100px;
				background: #fff !important;

				&:hover {
					&:before {
						opacity: 0.3;
					}
				}

				&:before {
					font-size: 34px;
					color: #000 !important;
				}
			}
		}

		.owl-carousel {
			.owl-item {
				img {
					height: 600px;
					object-fit: cover;
				}
			}

		}

		.owl-thumbs {
			width: 100%;
			max-width: 500px;
			float: right;
			display: inline-flex;
			-webkit-box-pack: end;
			justify-content: flex-end;
			position: relative;
			margin: -55px 0 0 0;
			z-index: 1;

			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
				padding: 0;
				border: none;
				border-radius: 0;
			}

			.owl-thumb-item {
				outline: 0 none;
				padding: 0;
				float: left;
				margin: 0;
				-webkit-box-flex: 1;
				flex: 1;
				border: 3px solid #fff;
				box-shadow: 3px 3px 2px rgb(0 0 0 / 10%);
				position: relative;
				cursor: pointer;
				background: #fff;

				&:nth-child(3) {
					&:before {
						z-index: 5;
						content: "+ more";
						position: absolute;
						color: #fff;
						background-color: rgba(0, 0, 0, .5);
						width: 100%;
						height: 100%;
						text-align: center;
						display: inline-block;
						padding-top: 25%;
						font-weight: 700;
						font-size: 20px;
						left: 0;
					}
				}

				&:nth-child(n+4) {
					display: none;
				}
			}
		}


	}

	.legend-cal {
		padding: 0;
		margin: 15px 0 0;

		ul {
			display: flex;
			align-items: center;
			flex-wrap: wrap;
			margin: 0;
		}

		li {
			display: inline-flex;
			align-items: center;
			min-height: 30px;
			padding-right: 5px;
			margin: 5px 0;

			@media (max-width:767px) {
				&:first-child {
					width: 100%;
				}

				text-align:left;
				width:50%;
			}

			.day {
				width: 30px;
				height: 30px;
				display: inline-block;
				margin: 0 10px;
				border: 1px solid #e4e7e7;

				@media (max-width:767px) {
					margin-left: 0;
				}

				&.day-free {
					background-color: var(--greenColor);
				}

				&.day-full {
					background-color: #dedede;
				}

				&.day-start {
					background: #ff932c;
					background: linear-gradient(135deg, #dedede 50%, var(--greenColor) 0) !important;
				}

				&.day-end {
					background: #ff932c;
					background: linear-gradient(135deg, var(--greenColor) 50%, #dedede 0) !important;
				}
			}
		}
	}

	.v-img-gallery {
		@media all and (max-width:991px) {
			img {
				width: 25%;
			}
		}

		@media all and (max-width:767px) {
			img {
				width: 50%;
			}
		}
	}

	.aboutus {
		padding: 15px 20px;
		background: var(--whiteColor);
		margin: 30px 0 0;

		i {
			min-width: 25px;
			color: var(--defaultColor);
		}
	}

	.contact-form {
		padding: 15px 20px;
		background: var(--whiteColor);
		margin: 30px 0 0;

		h4 {
			margin-bottom: 10px;
		}

		.checkbox {
			label {
				margin: 0;
				vertical-align: top;
			}
		}
	}

	.feedback {
		margin-top: 30px;

		.prop {
			margin-top: 8px;
		}

		.feedbacks {
			blockquote {
				padding: 10px 20px;
				margin: 20px 0 0;
				border-left: 5px solid #eee;
				background: var(--whiteColor);

				footer {
					background: var(--whiteColor);
					background: -moz-linear-gradient(-45deg, var(--whiteColor) 0, #eaeaea 100%);
					background: -webkit-linear-gradient(-45deg, var(--whiteColor) 0, #eaeaea 100%);
					background: linear-gradient(135deg, var(--whiteColor) 0, #eaeaea 100%);
					filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#eaeaea', GradientType=1);
					font-weight: 300;
					padding: 5px;
					border-top: 10px solid #fc0;
				}
			}
		}
	}

	.title-img-container {
		width: 100%;
		cursor: pointer;
	}

	.search-section {
		.searchbox {
			margin: 1px;
		}

		.search-popup-trigger {
			cursor: pointer;
		}

		.form-control:disabled,
		.form-control[readonly] {
			background-color: white;
		}

	}

	.sidebar-unit {

		.col-sm-3,
		.col-sm-9 {
			width: 100%;
		}

		.col-sm-offset-3 {
			margin-left: 0;
		}

		.form-group {
			margin-bottom: 5px;
		}
	}

	.title-img {

		position: relative;
		width: 100%;
		height: 700px;

		-webkit-background-size: cover;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center;


		.v-left-gallery-btn,
		.v-right-gallery-btn {
			font-size: 160px;
			position: absolute;
			top: 50%;
			margin-top: -57px;
		}

		.v-left-gallery-btn:hover,
		.v-right-gallery-btn:hover {
			color: #585858;
		}

		.v-right-gallery-btn {
			right: 40px;
		}

		.v-left-gallery-btn {
			left: 40px;
		}

	}



	.panorama {
		position: relative;
		width: 100%;
		height: 700px;
	}


	.stars {
		color: var(--infocolor);
	}


	.main-section {
		position: relative;
		clear: both;

		h2 {
			margin-bottom: 0;
			padding-bottom: 5px;

			.stars {
				display: inline-block;
				margin-left: 0;
				color: #666;
			}
		}

		.main-col {

			position: static;

			.stars {
				margin-top: 10px;
			}

			.description {
				padding: 20px 0;

				.show-more {
					display: inline-block;
					vertical-align: top;
					margin-top: 15px;
					color: var(--skyBlueColor);
				}
			}

			.intro {
				i {
					font-size: 26px;
				}

				>div {
					text-align: center;
				}
			}

			.properties {
				@mixin columnlayout 3;

				.prop {
					font-size: 13px;
				}

				.prop.missing {
					text-decoration: line-through;
					font-weight: normal;
				}

				.stars {
					display: inline-block;
					color: #dfb62d;
				}
			}

			.feedbacks {
				margin-top: 30px;

				blockquote {
					div {
						font-family: 'Indie Flower', cursive;
					}

				}
			}


		}

		.info-col-header {
			background-color: var(--primarycolor);
		}


		.info-col {
			margin-bottom: 30px;

			.info-col-header {
				background-color: var(--skyBlueColor);
				text-align: center;

				h2 {
					margin: 0;
					font-size: 18px;
					color: var(--whiteColor);
					padding: 10px;
				}
			}

			.info-col-main {
				border: 1px solid #888;
				border-bottom: none;
				width: 100%;
			}

			.subprice {
				//width: 100%;
				border: 1px solid #888;
				border-top: none;
			}

			.fav-section {
				margin-top: 20px;
				border-bottom: 1px solid #888 !important;
				padding: 15px;
			}

		}

		.info-col {

			.info-col-main {

				padding: 15px;

				.search-section.layout-row {
					display: block;

					.searchbox {
						margin: 5px 0;
						width: 100%;
					}
				}
			}

			.subprice {

				//padding: 1px 15px 15px 15px;

				.subrow {
					padding: 0 15px 15px 15px;
				}

				.optionals {
					h3 {
						padding: 0 15px;
						margin: 15px 0 10px;
					}
				}
			}
		}

		.price-list {
			max-width: 400px;

			.subline {
				.labelcol {
					padding-left: 20px;
				}

				td {
					border-top: none;
				}
			}

		}
	}


	.subsection {
		width: 100%;

		.unit-map {
			height: 470px;
			width: 100%;



			@media (max-width:992px) {
				height: 520px;
			}

			@media (max-width:420px) {
				height: 560px;
			}

			.mapnote {
				font-size: 14px;
				padding: 15px;
				background: #fff;

			}

			>div {
				height: 340px;

				@media (max-width:991px) {
					height: 400px;
				}
			}

			&.no-map {
				height: auto;

				.mapconsent {
					height: auto;
					display: flex;
					align-items: center;
					justify-content: center;
					text-align: center;
					background: rgba(26, 121, 187, 0.15);
					flex-direction: column;
					padding: 15px;
					margin: 15px 0;

					.doc-box {}

					.mapconsent-button {
						background: var(--primary);
						color: #fff;
						border-radius: 5px;
						padding: 0 15px 0 15px;
						height: 36px;
						display: block;
						line-height: 35px;

						&:hover {
							background: var(--secondary);
						}
					}
				}

				@media (max-width:420px) {
					height: auto;
				}

				>div {
					height: auto;

					@media (max-width:991px) {
						height: auto;
					}
				}
			}
		}
	}


	.matterport-showcase {
		text-align: center;

		iframe {
			min-height: 240px;
			margin: 16px 0;
			width: 100%;
		}

	}

	@media all and (max-width:767px) {

		.title-img {
			height: 350px;

			.v-left-gallery-btn,
			.v-right-gallery-btn {
				font-size: 80px;
			}

		}

		.panorama {
			height: 350px;
		}


		.page-header {

			h1 {
				font-size: 24px;
			}
		}

		.main-section {

			.main-col {
				.properties {
					@mixin columnlayout 1;
				}
			}
		}

		.unit-slider {
			.owl-nav {
				display: none;
			}

			.owl-carousel {
				.owl-item {
					img {
						height: 420px;
					}
				}
			}

			.owl-thumbs {
				.userimg {
					height: 80px;
				}
			}
		}

	}


	@media (min-width:768px) and (max-width:992px) {

		.title-img {
			height: 465px;
		}

		.panorama {
			height: 465px;
		}

		.matterport-showcase iframe {
			min-height: 360px;
		}


	}

	@media (min-width:993px) and (max-width:1199px) {

		.title-img {
			height: 580px;
		}

		.panorama {
			height: 580px;
		}

		.matterport-showcase iframe {
			height: 600px;
			max-width: 1068px;
			margin: 0 auto 15px;

		}


	}


	@media screen and (min-width: 993px) {
		.matterport-showcase iframe {
			height: 600px;
			max-width: 1068px;
			margin: 0 auto 15px;

		}

	}




}


.facility-view {

	h2 {
		margin-top: 40px;
		font-size: 22px;
	}

	.reduced-width-container {
		max-width: 970px !important;

		.row {
			padding: 5px;
		}

	}
}

.unit-proposals,
.unit-list-section {

	.single-unit {
		float: left;
		margin: 4px;
		background-color: white;
		padding: 3px;

		a {
			color: inherit;
			text-decoration: none;
		}

		h3 {
			margin-top: 10px;
			font-size: 15px;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			width: 224px;
		}

		.udetails {

			padding: 2px;
			opacity: 0.7;
			position: relative;

			.prop {
				padding-right: 4px;
			}


			.door {
				width: 10px;
				height: 15px;
				display: inline-block;
				background: no-repeat url(RESOURCE/img/door-smaller.png) 0 0;
				position: relative;
				top: 2px;
			}

		}
	}
}

.unit-proposals {
	background-color: #f8f8f8;
}