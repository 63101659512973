.indexpage {
	.brief {

		.content-section {
			margin-top: 15px;
		}

		h2 {
			font-weight: 400;
		}

		&.home-unit-bg {
			background-image: var(--homeUnitBg);

			h2 {
				margin-bottom: 0;
			}
		}
	}
}